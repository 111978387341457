@media (max-width: 1200px){

    .page-body-ctn {
        max-width: 800px;
    } 
    /* stacked */
    .stacked-img-grid-ctn{
        max-width: 800px !important;
    }
    .slick-slide img {
        width: 550px !important;
        height: 100% !important;
    }
    /* aestige */
    .aestige-img-grid-ctn{
        max-width: 800px !important;
    }
    /* personal site */
    .personal-img-grid-ctn{
        max-width: 800px !important;
        grid-template-columns: repeat(4,auto) !important;
        grid-template-rows: repeat(2, auto) !important;   
    }
}

@media (max-width: 800px){

    .page-body-ctn {
        max-width: 375px;
        padding: 0px 32px !important;
    } 
    /* stacked */
    .stacked-img-grid-ctn{
        max-width: 600px !important;
        padding: 0px 32px 100px 32px;
    }
    .slick-slide img {

        width: 400px !important;
        height: 100% !important;
    }
    .proj-text-container {
        display: grid;
        grid-template-columns: 1fr ;
        gap: 16px;
    }
    /* aestige */
    .aestige-img-grid-ctn {
        max-width: 375px !important;
        grid-template-columns: repeat(2, auto) !important;
        grid-template-rows: repeat(4, auto) !important;
    }
    .a-img1{
        grid-column: 1/3;
        grid-row: 1/2;
    }
    .a-img2{
        grid-column: 1/2 !important;
        grid-row: 3/4 !important;
    }
    .a-img3{
        grid-column: 2/3 !important;
        grid-row: 3/5 !important;
    }
    .a-img4{
        grid-column: 1/3 !important;
        grid-row: 2/3 !important;
    }
    .a-img5{
        grid-column: 1/2 !important;
        grid-row: 4/5 !important;
    }

        /* personal site */
        .personal-img-grid-ctn{
            max-width: 375px !important;
        grid-template-columns: repeat(2,auto) !important;
        grid-template-rows: repeat(4, auto) !important;   
        }
        .p-img1{
            grid-column: 1/2 !important;
            grid-row: 3/5 !important;

        }
        .p-img2{
            grid-column: 2/3 !important;
            grid-row: 3/5 !important;
        }
        .p-img3{
            
            grid-column: 1/3;
            grid-row: 2/3;
        }
        .p-img4{
            
            grid-column: 1/3;
            grid-row: 1/2;
        }

}
@media (max-width: 600px){
    .page-body-ctn {
        max-width: 375px;
        padding: 0px 16px !important;
    } 
    /* stacked */
    .slick-next{
        display: none !important;
    }
    .slick-prev{
        display: none !important;
    }

    .stacked-img-grid-ctn{
        max-width: 600px !important;
        padding: 0px 16px 100px 16px !important;
    }
    .slick-slide img {

        width: 430px !important;
        height: 100% !important;
    }
}

@media (max-width: 530px){

    /* stacked */
    .stacked-img-grid-ctn{
        max-width: 500px !important;
        padding: 0px 8px 100px 8px !important;

    }
    .slick-slide img {

        width: 370px !important;
        height: 100% !important;
    }

}


@media (max-width: 470px){

    /* stacked */
    .stacked-img-grid-ctn{
        max-width: 100% !important;
    }
    .slick-slide img {

        width: 350px !important;
        height: 100% !important;
    }
}

@media (max-width: 450px){

    /* stacked */

    .slick-slide img {

        width: 330px !important;
        height: 100% !important;
    }
}
@media (max-width: 410px){

    /* stacked */
 
    .slick-slide img {

        width: 300px !important;
        height: 100% !important;
    }
}