.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  padding: 0px 32px;
  max-width: 1200px;
  margin: 32px auto 32px auto;
  font-weight: 500;
}
.navbar {
  background-color: var(--filter-background-color);
  border-radius: 100px;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out;
  padding: 4px 5px;
}
.nav-list {
  display: flex;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
}
.filter {
  padding: 8px 16px;
}
.filter {
  cursor: pointer;
  border-radius: 100px;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out;
}
.filter.active {
  background-color: var(--filter-active-color);
}
.filter:hover {
  color: var(--filter-text-color-on-hover);
}
.filter.active:hover {
  cursor: default;
  color: var(--text-color);
}
.toggle-button {
  position: relative;
  display: block;
  cursor: pointer;
  height: 32px;
  width: 56px;
  background: var(--toggle-background);
  box-shadow: var(--toggle-box-shadow);
  transition: var(--toggle-transition);
  border-radius: 100px;
}
.switch {
  position: absolute;
  left: var(--switch-position-left);
  top: 4px;
  border-radius: 50%;
  background: var(--switch-background);
  box-shadow: var(--switch-box-shadow);
  transition: 0.3s ease-in-out;
  height: 24px;
  width: 24px;
}
.toggle {
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.toggle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 13px;
  pointer-events: none;
}
.icon-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.sun,
.dark .moon {
  opacity: 1;
  transition: 0.2s;
}

.moon,
.dark .sun {
  opacity: 0;
  transition: 0.2s;
  fill: white;
}

.contact-button {
  display: flex;
  border: none;
  cursor: pointer;
  padding: 4px;
  padding: 4px 4.5px 4px 4.5px;
  background: var(--filter-background-color);
  border-radius: 100px;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out;
}
a {
  text-decoration: none;
  color: #1d1d1d;
}
.nav-contact {
  text-decoration: none;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 100px;
  transition: var(--transition-delay);
  background: var(--primary-button-color);
}
