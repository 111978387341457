@font-face {
    font-family: "Silka Regular";
    src: url("/public/fonts/silka-regular-webfont.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Silka Medium";
    src: url("/public/fonts/Silka-Medium.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Moranga Bold";
    src: url("/public/fonts/Moranga-BoldIt.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }

.project-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    padding: 0px 32px;
    max-width: 1200px;
    margin: 0px auto 0px auto;
    font-weight: 500;
}

.page-btn-link{
    border: 2px solid rgb(48, 54, 61);
    width: fit-content;
    display: flex;
    cursor: pointer;
    padding: 0px 15px;
    height: 38px;
    align-items: center;
    border-radius: 100px;
}
.page-btn-link-text{
    font-family: "Silka Medium" !important;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    color: var(--text-color);
}

.hidevisit{
    display: none;
}
.x-ic-size{
    height: 20px;
    width: 20px;
}
.arrow-proj-size{
    width: 20px;
    height: 20px;
    margin-left:8px;
}
.page-btn-link:hover{
    transition: all 0.2s ease-out 0s;
    cursor: pointer;
    background: rgb(33, 38, 45);
}
.page-body-ctn {
    max-width: 1200px;
    padding: 0 60px;
    margin: 0 auto;
    margin-bottom: 50px;
} 
.text-ctn{
    display: flex;
    justify-content: space-between;
}
.about-ctn{
    /* width: 500px; */
    text-align: left;
}

.title-container{
    text-align: left;
    font-family: 'system-ui', sans-serif;
width: 500px;
}
.proj-text-container{
    /* display: flex;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

}
.project-title{
    font-size: 36px;
    font-family: "Moranga Bold";
    line-height: 48px;
    font-weight: 400;
    margin-bottom: 8px;
}
.project-sub-title{
    font-size: 26px;
    font-family: "Silka Medium";
    line-height: 40px;
    letter-spacing: 0.25px;
    font-weight: 400;
    margin-bottom: 16px;
    
}






.img-container{
    display: flex;
    justify-content: center;
    /* max-width: 700px; */
    margin: 0 auto;

}
.project-img{
    width: 100%;
    display: flex;
justify-content: center;
justify-self: center;
align-items: center;
width: 100%; /* Ensures image fills the container */
height: 100%; /* Ensures image fills the container */
object-fit: cover; /* Maintains aspect ratio */
border-radius: 10px;
}
.project-text-container{
    text-align: center;
    margin-bottom: 150px;    
    
}
.text-title{
    font-size: 28px;
}
.projcect-text{
    font-size: 15px;
    letter-spacing: 1.0px;
    max-width: 100ch;
    margin: 20px auto;
    text-align: center;
    font-family: "Silka Regular";
    line-height: 26px;
    font-weight: 400;
    text-align: left;
 
}
.projcect-text p{
    margin:20px 0px
}
.about-text-container{
    grid-column: 3/4;
    grid-row: 1/2;
}
.struggle-text-container{
    grid-column: 1/3;
    grid-row: 2/3;
}
.about-text-container{
    display: grid;
    grid-template-columns: repeat(1, 400px);
    grid-template-rows: repeat(2, auto);
    gap: 20px;

}
