.container {
  max-width: 1200px;
  padding: 0 32px;
  margin: 0 auto;
}
.grid {
  display: grid;
  position: relative;
  max-width: 100%;
  grid-template-columns: repeat(4, 266px);
  grid-template-rows: repeat(4, 266px);
  column-gap: 24px;
  row-gap: 24px;
  margin-bottom: 80px;
}
.card {
  border-radius: 32px;
  background-color: var(--card-color);
  box-shadow: 0.2rem 0.4rem 1rem rgba(0, 0, 0, 0.08);
  padding: 32px;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-bottom 0.4s ease-in-out;
}

.content-logo {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  gap: 3px;
}

/* FILTER LAYOUT */
/*               */
/*               */

/*           */
/* CARD--ALL */
/*           */
#card--intro--all {
  grid-column: 1/3;
  grid-row: 1/2;
}
#card--crypto--all {
  grid-column: 3/4;
  grid-row: 1/1;
}
#card--learning--all {
  grid-column: 4/5;
  grid-row: 1/3;
}
#card--project1--all {
 
  grid-column: 2/3;
  grid-row: 3/5;
}
#card--map--all {
  grid-column: 2/4;
  grid-row: 2/3;
}
#card--project2--all {

  grid-column: 1/2;
  grid-row:5/7;
}
#card--project3--all {
  grid-column: 1/2;
  grid-row: 2/4;
}
#card--spotify--all {
  grid-column: 3/5;
  grid-row: 3/4;
}
#card--github--all {
  grid-column: 3/5;
  grid-row: 4/5;
}
#card--linked-in--all {
  grid-column: 1/2;
  grid-row: 4/5;
}

/*           */
/* CARD--ABOUT */
/*           */
#grid--about {
  grid-template-columns: repeat(4, 266px);
  grid-template-rows: repeat(3, 266px);
}
#card--intro--about {
  grid-column: 1/3;
  grid-row: 1/3;
}
#card--crypto--about {
  grid-column: 3/4;
  grid-row: 1/1;
}
#card--map--about {
  grid-column: 3/5;
  grid-row: 2/3;
}
#card--spotify--about {
  grid-column: 3/5;
  grid-row: 3/4;
}
#card--github--about {
  grid-column: 1/3;
  grid-row: 3/4;
}
#card--linked-in--about {
  grid-column: 4/5;
  grid-row: 1/2;
}

/*           */
/* CARD--PROJECTS */
/*           */

#grid--projects {
  grid-template-columns: repeat(4, 266px);
  grid-template-rows: repeat(2, 266px);
}
#card--project1--projects {
  grid-column: 2/3;
  grid-row: 1/3;
}

#card--project2--projects {
  grid-column: 3/4;
  grid-row: 1/3;
}
#card--project3--projects {
  grid-column: 1/2;
  grid-row: 1/3;
}

/*           */
/* CARD--MEDIA */
/*           */

#grid--media {
  grid-template-columns: repeat(4, 266px);
  grid-template-rows: repeat(2, 266px);
}
#card--github--media {
  grid-column: 1/3;
  grid-row: 1/2;
}
#card--linked-in--media {
  grid-column: 3/5;
  grid-row: 1/2;
}
