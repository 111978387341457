.aestige-img-grid-ctn{
    max-width: 1200px;
    margin: auto;
    padding: 0 32px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(2, auto);
    column-gap: 20px;
    row-gap: 10px;
}
.aestige-img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
.a-img1{
    grid-column: 1/3;
    grid-row: 1/2;
}
.a-img2{
    grid-column: 3/4;
    grid-row: 1/2;
}
.a-img3{
    grid-column: 4/5;
    grid-row: 1/3;
}
.a-img4{
    grid-column: 1/3;
    grid-row: 2/3;
}
.a-img5{
    grid-column: 3/4;
    grid-row: 2/3;
}