@font-face {
  font-family: "Shrikhand";
  src: url("/public/fonts/Shrikhand-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.card-intro {
  grid-column: 1/3;
  grid-row: 1/2;
}
.memoji {
  display: flex;
  align-items: baseline;
  justify-content: baseline;
}
#card--intro--about .intro-img {
  height: 170px;
}
.intro-img {
  height: 120px;
  z-index: 1;
}
.intro-text {
  font-size: 16px;
  text-align: center;
  line-height: 1.2;
}
.headline {
  text-align: left;
  font-size: 16px;
}
#card--intro--about .headline {
  font-size: 18px;
}
#card--intro--about .intro-text {
  margin-top: 20px;
  line-height: 1.4;
}
#card--intro--about .Mike {
  font-size: 70px;
}
.Mike {
  font-size: 50px;
}
.expand-button {
  display: flex;
  justify-content: center;
  background: none;
    border: none;
    color: inherit;
    width: 100%;

}
.arrow-expand {
  margin-top: 10px;
  height: 20px;
  fill: var(--text-color);
  cursor: pointer;
}
