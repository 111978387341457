.personal-img-grid-ctn{
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4,269px);
    grid-template-rows: repeat(2, 270px);
    column-gap: 20px;
    row-gap: 23px;
    padding: 0 32px;
    padding-bottom: 100px;

}
.personal-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
}
.p-img1{
    grid-row: 1/3;
    grid-column: 3/4;
}
.p-img2{
    grid-row: 1/3;
    grid-column: 4/5;
}
.p-img3{
    
    grid-column: 1/3;
    grid-row: 2/3;
}
.p-img4{
    
    grid-column: 1/3;
    grid-row: 1/2;
}