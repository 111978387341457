.sm-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-media-logo {
  fill: #ffffff;
  height: 100px;
  width: 100px;
  transition: 0.4s ease-in-out;
}

.card-linked-in:hover .social-media-logo {
  transform: scale(1.2);
  transition: 0.4s ease-in-out;
}

.card-github:hover .social-media-logo {
  transform: scale(1.2);
  transition: 0.4s ease-in-out;
}
.card-github {
  grid-row: 4/5;
  grid-column: 3/5;
  background-color: #2c2c2e;
  transition: 0.4s ease-in-out;
}
.card-linked-in {
  background: #5285ce;
  grid-column: 1/1;
  grid-row: 4/5;
}
