.card-learning-path {
  grid-column: 4/5;
  grid-row: 1/3;
  background-color: var(--card-color);
  border-radius: 32px;
  text-align: center;
  padding: 32px;
}
.heading-secondary {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  pointer-events: none;
  
}
.content-container {
  border-bottom: 2px solid var(--filter-background-color);
  padding: 25px 0;
}
.content-container:last-child {
  border-bottom: none;
  padding: 15px 0 0 0;
}
.content-header {
  display: flex;
  text-align: left;
  align-items: center;

}
.content-logo {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  gap: 3px;
}
.logo-vector {
  height: 20px;
  width: 20px;
}
.content-title {
  width: 100%;
}
.learning-course {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.1;
  width: 26ch;
  color: var(--text-color);
  transition: var(--transition-delay);
}
.content-progress {
  font-weight: 500;
  font-size: 12px;
  color: var(--subheading-color);
  display: flex;
  margin-left: 40px;
  pointer-events: none;
}
.last-content-progress {
  margin-top: -10px;
}
