.card-project1 {
  grid-column: 1/2;
  grid-row: 2/4;
}
.card-project2 {
  grid-column: 2/3;
  grid-row: 3/5;
}
.built-with-container {
  height: 80px;
}

.subheading {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: var(--subheading-color);
  margin-bottom: 4px;
  pointer-events: none;
}
.project-logo {
  display: flex;
  gap: 4px;
  margin-top: 7px;
  align-items: center;
  max-width: 200px;
  flex-wrap: wrap;
}
.project-preview-container {
  height: 270px;
  width: 202px;
  margin: 10px 0px;
  border-radius: 3px;
}
.btn-container {
  margin-top: 15px;
}
.project-btn {
  padding: 20px;
  background-color: var(--card-color);
  display: relative;
  height: 48px;
  width: 48px;
  border-radius: 100px;
  position: relative;
  display: flex;
  transition: all 0.3s ease-out;
  border: 4px solid var(--filter-background-color);
  cursor: pointer;
}

.project-card:hover .project-btn {
  width: 100px;
  align-items: center;
}
.arrow {
  position: absolute;
  top: 7.5px;
  right: -4px;
  width: 36px;
  height: 36px;
  align-items: center;
}
.arrow-text {
  position: absolute;
  top: 1;
  left: 4px;
  display: none;
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
  font-size: 14px;
  transition: all 1s ease-in-out;
  opacity: 0;
  transform: translateX(-100%);
  white-space: nowrap;
  transition-delay: 0.2s;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  text-align: center;
  margin-right: 10px;
  color: var(--text-color);
}
.project-card:hover .arrow-text {
  display: inline-block;
  opacity: 0;
  animation: fadein 0.3s 0.2s forwards;
}
@keyframes fadein {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
.project-image{
  height: 250px;
  margin-left: -12px;
  border-radius: 15px;
}