

.stacked-img-grid-ctn{
    max-width: 1200px;
    margin: auto;
    padding: 0 60px;
    padding-bottom: 100px;
    border: none !important;
}
.slick-slide img {
    display: block;
    height: 500px;
    border-radius: 10px;
    outline: none !important;
    border: none !important;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust this value as needed */
    outline: none !important;
    border: none !important;
}

.slick-slide div:focus-visible{
     outline: none !important;
}


.slick-initialized .slick-slide {

    display: flex !important;
    justify-content: center;
    outline: none !important;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.stacked-img1{
    grid-column: 1/3;
    grid-row: 1/2;
}
.stacked-img2{
    grid-column: 3/4;
    grid-row: 1/2;
    /* height: 394px !important; */
}
.stacked-img3{
    grid-column: 1/3;
    grid-row: 2/3;
    /* height: 394px !important; */
}
.stacked-img{
    /* height: 100%; */
    width: 100%;
    object-fit: cover;
}

/* @media (max-width: 1200px){
    .stacked-img-grid-ctn{
        max-width: 800px;
    }
} */
.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: WHITE !important;
}

.slick-dots li button:before {
    opacity: 1 !important;
    color: grey !important;
}
.slick-dots{
    bottom: -35px !important;
}