#card--spotify {
  grid-column: 3/5;
  grid-row: 3/4;
}
.card-spotify {
  display: grid;
  grid-template-columns: 202px 1fr;
  grid-template-rows: repeat(3, 1fr);
  column-gap: 64px;
  column-gap: 56px;
}

.img-container {
  grid-column: 1/2;
  grid-row: 1/3;
}
#song-img {
  width: 202px;
  height: 202px;
  border-radius: 12%;
  box-shadow: 0.2rem 0.4rem 1.2rem rgba(0, 0, 0, 0.08);
}
.spotify-container {
  width: 100%;
  height: 100%;
}
.spotify-logo {
  grid-column: 2/3;
  grid-row: 1/2;
  width: 48px;
  height: 48px;
}
.song-info {
  grid-column: 2/3;
  grid-row: 3/4;
}
.playing-status-container {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.animation-container {
  margin-right: 4px;
  display: flex;
  align-items: center;
}
.spotify-animation-1,
.spotify-animation-2,
.spotify-animation-3 {
  width: 3px;
  border-radius: 1.5px;
  background-color: #2ad45e;
  margin-right: 3px;
}

.spotify-animation-1 {
  animation: playing 0.8s infinite;
}

.spotify-animation-2 {
  animation: playing 1s infinite;
}

.spotify-animation-3 {
  animation: playing 1.2s infinite;
}
@keyframes playing {
  0% {
    height: 3px;
  }
  50% {
    height: 12px;
  }
  100% {
    height: 3px;
  }
}
.current-status {
  font-size: 16px;
  font-weight: 600;
  color: #2ad45e;
  pointer-events: none;
}
.song-name {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}
#spotify-song {
  color: var(--text-color);
  transition: var(--transition-delay);
}
.artist-name {
  font-size: 16px;
  font-weight: 500;
  pointer-events: none;
}
