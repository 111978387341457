.card-map {
  grid-column: 2/4;
  grid-row: 2/3;
  padding: 0px;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  overflow: hidden !important;
  box-shadow: 0.2rem 0.4rem 1.2rem rgba(0, 0, 0, 0.08);
}
#map {
  position: relative;
  width: 100%;
  height: 100%;
}
.maplibregl-ctrl-attrib-button,
.mapboxgl-ctrl-attrib-button,
.maplibregl-ctrl,
.maplibregl-ctrl-attrib,
.mapboxgl-ctrl,
.mapboxgl-ctrl-attrib,
.maplibregl-compact,
.mapboxgl-compact,
.maplibregl-ctrl-bottom-right,
.mapboxgl-ctrl-bottom-right {
  content-visibility: hidden;
}
